import React from 'react';
import Divider from '@material-ui/core/Divider';
import Mask from '@components/mask/Mask';
import { CircularProgress } from '@components/progress/Progress';
import CustomerMessageEntity from '@powerednow/shared/modules/complexData/customerMessage/entity';
import { ModelCreationFields } from '@powerednow/shared/modules/complexData/entity';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ScrollToBottom from '@components/ScrollToBottom/ScrollToBottom';
import MessageViewItem from './messageViewItem';

interface MessageHistoryStyles {
    containerBackgroundColor: string,
}

const useStyles = makeStyles<Theme, MessageHistoryStyles>(() => createStyles({
    messageContainer: {
        height: '50vh',
        maxHeight: '500px',
        overflow: 'auto',
        backgroundColor: ({ containerBackgroundColor }) => containerBackgroundColor,
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        whiteSpace: 'normal',
        padding: '1rem',
    },
}));

type ViewMessageProps = {
    loaded: boolean,
    containerBackgroundColor: string,
    messages: ModelCreationFields<CustomerMessageEntity>[],
    emptyComponent: JSX.Element,
}

export default function MessageHistory(props: ViewMessageProps): JSX.Element {
    const {
        loaded,
        containerBackgroundColor,
        messages,
        emptyComponent,
    } = props;
    const classes = useStyles({ containerBackgroundColor });
    return loaded && !messages[0]
        ? (emptyComponent)
        : (
            <Mask show={!loaded} content={<CircularProgress />}>
                <div className={classes.messageContainer}>
                    <ScrollToBottom>
                        {messages[0] ? messages.filter(Boolean).map(message => <MessageViewItem key={String(message.id)} messageData={message} />) : <p />}
                    </ScrollToBottom>
                </div>
                <Divider />
            </Mask>
        );
}
